import { atom, selector, selectorFamily, AtomEffect, useSetRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import _ from 'underscore';

/* Persistance Codess */

const { persistAtom } = recoilPersist();

const ssrCompletedState = atom({
    key: 'SsrCompleted',
    default: false,
});

export const useSsrComplectedState = () => {
    const setSsrCompleted = useSetRecoilState(ssrCompletedState);
    return () => setSsrCompleted(true);
};

export const persistAtomEffect = (param) => {
    param.getPromise(ssrCompletedState).then(() => persistAtom(param));
};

/* Cart Selected Products Atom */

export const cartProductState = atom({
    key: 'cartProductState',
    default: [],
    effects_UNSTABLE: [persistAtomEffect],
});

export const countByIdState = selector({
    key: 'countByIdState',
    get: ({ get }) => {
        const products = get(cartProductState);
        const count = _.countBy(products, (product) => product.id);
        return count;
    },
});

export const countByIdAndSideState = selector({
    key: 'countByIdAndSideState',
    get: ({ get }) => {
        const products = get(cartProductState);

        const hashMealAndSide = (product) => {
            const mealId = product.id;
            const sideId = product?.side?._id;
            return mealId + sideId;
        };

        const count = _.countBy(products, (product) => hashMealAndSide(product));
        return count;
    },
});

export const countByTypeState = selector({
    key: 'countByTypeState',
    get: ({ get }) => {
        const products = get(cartProductState);
        console.log(products, 'product');
        const count = _.countBy(products, (product) => product.type);
        return count;
    },
});

export const countByDayState = selector({
    key: 'countByDayState',
    get: ({ get }) => {
        const products = get(cartProductState);
        const count = _.countBy(products, (product) => product.day);
        return count;
    },
});

export const cartPriceState = selectorFamily({
    key: 'cartPriceState',
    get:
        (company, user) =>
        ({ get }) => {
            if (!company) return 0;
            const productPrices = company.products;
            const countByType = get(countByTypeState);
            const cartChoices = get(cartChoiceState);
            const dayCount = cartChoices?.delivery?.value?.days?.length;
            let singleShippingPrice = 675;

            if (user) {
                const weekdays = ['Sunday', 'Tuesday', 'Thursday'];
                const shippingAddress = user.contact.address;
                const dayKeys = Object.keys(shippingAddress);
                if (dayKeys.length == 0) return;

                const filteredDayKeys = dayKeys.filter((key) => weekdays.includes(key));
                const firstDay = filteredDayKeys[0];
                const firstShippingAddress = shippingAddress[firstDay];
                const county = firstShippingAddress.county;
                const sixEuroCounties = 'Co. Dublin';
                if (county != sixEuroCounties) singleShippingPrice = 875;
            }

            const getTypePrice = (type) => {
                let price = 0;
                const quantity = countByType[type];
                const typePrices = productPrices?.[type]?.pricing;

                if (!typePrices) return 0;
                Object.keys(typePrices).forEach((bracket) => {
                    if (quantity >= bracket) price = typePrices[bracket];
                });

                return price * quantity;
            };

            let price = Object.keys(countByType).reduce(
                (acc, type) => (acc += getTypePrice(type)),
                0
            );

            const priceWithShipping = price + singleShippingPrice;
            const priceWithoutShipping = price;

            const brackets = company?.settings?.discount?.volume;

            var discount = 0;

            brackets.forEach((bracket) => {
                if (priceWithShipping > bracket.min) {
                    discount = bracket.percent;
                }
            });

            const volumeDiscount = priceWithShipping * (discount / 100);
            const priceAfterVolumeDiscount = priceWithShipping - volumeDiscount;

            return {
                priceWithShipping,
                priceWithoutShipping,
                volumeDiscount,
                priceAfterVolumeDiscount,
            };
        },
});

export const sortedProductsState = selector({
    key: 'sortedProductsState',
    get: ({ get }) => {
        const cartProducts = get(cartProductState);

        const groupedByDay = _.groupBy(cartProducts, 'day');
        const groupedByDayAndType = {};

        Object.keys(groupedByDay).forEach((day) => {
            groupedByDayAndType[day] = _.groupBy(groupedByDay[day], 'type');
        });

        return groupedByDayAndType;
    },
});

export const stripeProductsState = selectorFamily({
    key: 'stripeProductsState',
    get:
        (company, user) =>
        ({ get }) => {
            if (!company) return [];
            console.log(user, 'user');
            const products = company.products;
            const countByType = get(countByTypeState);
            const cartChoices = get(cartChoiceState);
            const dayCount = cartChoices?.delivery?.value?.days?.length;

            const cartProducts = [];

            Object.keys(countByType).forEach((type) => {
                const id = products[type].priceId;
                const quantity = countByType[type];
                const price = 0;
                Object.keys(products[type].pricing).forEach((bracket) => {
                    if (quantity >= bracket) {
                        price = products[type].pricing[bracket];
                    }
                });
                if (quantity > 0) {
                    cartProducts.push({ id, type, quantity, price });
                }
            });

            /* cartProducts.push({
                id: products.shipping.priceId,
                type: 'shipping',
                quantity: dayCount,
                price: products.shipping.pricing[0],
            }); */

            return cartProducts;
        },
});

export const cartCaloriesState = selector({
    key: 'cartCaloriesState',
    get: ({ get }) => {
        const products = get(cartProductState);
        return products.length * 16;
    },
});

/* Cart Choices Atoms */

export const cartChoiceState = atom({
    key: 'cartChoiceState',
    default: {},
    effects_UNSTABLE: [persistAtomEffect],
});

export const choicePriceState = selectorFamily({
    key: 'choicePriceState',
    get:
        (company) =>
        ({ get }) => {
            if (!company) return 0;
            const productPrices = company.products;
            const choices = get(cartChoiceState);

            const choicesWeCount = ['breakfast', 'main', 'snack'];

            const filteredChoices = Object.keys(choices).filter((choice) => {
                if (choicesWeCount.includes(choice)) return true;
            });

            const getTypePrice = (type) => {
                let price = 0;
                const quantity = choices?.[type]?.value?.quantity;
                const typePrices = productPrices[type]?.pricing;

                Object.keys(typePrices).forEach((bracket) => {
                    if (quantity >= bracket) price = typePrices[bracket];
                });

                return price * quantity;
            };

            let price = filteredChoices.reduce((acc, type) => (acc += getTypePrice(type)), 0);

            const getShippingPrice = () => {
                const numberOfShippingDays = choices?.delivery?.value?.days
                    ? choices.delivery.value.days.length
                    : 2;
                const shippingPrice = productPrices.shipping.pricing[0];
                return shippingPrice * numberOfShippingDays;
            };

            const priceWithShipping = price + getShippingPrice();
            const priceWithoutShipping = price;

            return { priceWithShipping, priceWithoutShipping };
        },
});
